html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  font-family: 'Roboto', sans-serif;
  background-color: #fff;
  color: #000;
  transition: background-color 0.3s, color 0.3s;
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  text-align: center;
  padding: 20px;
  box-sizing: border-box;
  min-height: 100vh;
}

header {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

h1 {
  font-size: 2.5rem;
  margin: 0;
  font-weight: 300;
  letter-spacing: 1px;
}

.theme-toggle {
  position: absolute;
  top: 0;
  right: 20px;
  font-size: 1.5rem;
  background: none;
  border: none;
  cursor: pointer;
  color: inherit;
  transition: transform 0.3s;
}

.theme-toggle:hover {
  transform: scale(1.2);
}

.input-container {
  display: flex;
  align-items: flex-end;
  gap: 20px;
  margin-bottom: 40px;
}

.input-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

label {
  margin-bottom: 5px;
  font-size: 1.2rem;
}

input {
  padding: 10px;
  font-size: 1.2rem;
  border-radius: 8px;
  border: 1px solid #ccc;
  background-color: #f8f8f8;
  color: #333;
  transition: background-color 0.3s, color 0.3s;
}

.input-info {
  margin-top: 5px;
  font-size: 0.9rem;
  color: #888;
}

button {
  padding: 12px 24px;
  font-size: 1.2rem;
  cursor: pointer;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 8px;
  transition: background-color 0.3s, transform 0.2s;
}

button:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

.result-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  text-align: center;
}

.result-container h2 {
  margin: 0;
  font-size: 1.8rem;
}

.result {
  margin-top: 10px;
  font-size: 2.5rem;
  font-weight: bold;
}

body.dark {
  background-color: #181818;
  color: #e0e0e0;
}

.App.dark input {
  background-color: #333;
  color: #fff;
  border: 1px solid #555;
}

.App.dark button {
  background-color: #0069d9;
}

.App.dark button:hover {
  background-color: #004085;
}

.input-info {
  color: #888;
}

.App.dark .input-info {
  color: #bbb;
}

@media (max-width: 600px) {
  .input-container {
    flex-direction: column;
    align-items: center;
  }

  input, button {
    width: 100%;
    margin-bottom: 10px;
  }

  h1 {
    font-size: 2rem;
  }

  .result {
    font-size: 2rem;
  }
}
